.SimpleParagraph {
  @apply flex flex-col justify-center text-center;
}

.SimpleParagraph h2 {
  @apply text-2xl mb-4;
}

.SimpleParagraph h3 {
  @apply text-xl md:mx-20;
}

.SimpleParagraph figure {
  @apply flex justify-center;
}
